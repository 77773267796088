import React from "react";
import Award from "../components/Award";

const ProcessDetails = ({ pageRef }) => {
  return (
    <div ref={pageRef} id="award">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }}>AWARDS</h1>
      <br></br>
      <br></br>
      <Award />
    </div>
  );
};

export default ProcessDetails;
