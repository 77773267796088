import React from "react";
import Intro from "../components/whatIsHackX";
import Header from "../components/Header"

const WhatIsHackXPage = ({ pageRef }) => {
  const pageStyle = {
    marginTop: '20px',
    marginLeft: '10px',  // Adjust these values as needed
    marginRight: '10px', // Adjust these values as needed
    textAlign: 'center',
    paddingLeft: '10px', // Additional padding for extra spacing
    paddingRight: '10px' // Additional padding for extra spacing
  };

  return (
    <div ref={pageRef} style={pageStyle}>
      <Header />
      <Intro />
      <br></br>
    </div>
  );
};

export default WhatIsHackXPage;