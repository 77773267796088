import React from 'react';
import { Card, Button } from 'react-bootstrap';
import"./App.css";

const Process = () => {
  return (
    <center>
      <Card className="custom-card mx-3"
          style={{
          background: "rgba(255, 255, 255, 0.2)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(1.6px)",
          WebkitBackdropFilter: "blur(1.6px)",
          overflow: "hidden",
          borderRadius: '25px'
        }}
      >
        <Card.Body>
          <ul className="custom-list">
            <li className="yellow-bullet">All participants must be undergraduates of a University or a Higher Education Institute.</li><br></br>
            <li className="yellow-bullet">A team should consist of 3 to 5 members representing the same University or Institute.</li><br></br>
            <li className="yellow-bullet">One contestant can represent only one team.</li><br></br>
            <li className="yellow-bullet">The ideas presented must be original and innovative.</li><br></br>
            <li className="yellow-bullet">Ideas must be documented in a proposal format that complies with the provided guidelines.</li><br></br>
            <li className="yellow-bullet"> Once submissions are made, the proposed idea cannot be amended, although the final product may include <br style={{marginLeft:"2px"}}></br>additional features.</li><br></br>
          </ul>
          <center>
            <Button className="custom-button">All Rules And Regulations</Button>
          </center>
        </Card.Body>
      </Card>
    </center>
  );
};

export default Process;
