import React, { useState, useRef } from "react";

const faqs = [
    {
        id: 1,
        header: "Who can participate in this competition?",
        text: `hackX 9.0 welcomes the participation of undergraduate students from state Universities or Higher Educational Institutes. Each participating team must appoint a team leader, and the team can have a maximum of 5 members representing the same university, as outlined in the registration guidelines.`
    },
    {
        id: 2,
        header: "Is registration free?",
        text: `Participation in the event incurs no registration fee; it is entirely free of charge.`
    },
    {
        id: 3,
        header: "Can a university register multiple teams in the competition?",
        text: `While multiple teams from a University can register for the competition, each team must have a distinct team name and a unique set of team members. Participants cannot represent more than one team, and if the same member is found in two different teams, both teams will be automatically disqualified`
    },
    {
        id: 4,
        header: "What happens after completing the registration process? ",
        text: `Once the registration process is successfully completed, team members will be eligible to submit their proposals within the specified timeframe, with all guidelines met. Furthermore, the team leader will receive confirmation or acknowledgement following registration.`
    },
    {
        id: 5,
        header: "How will the selected teams be notified? ",
        text: `Selected teams will be announced on the official hackX Facebook page and notified to you via the contact information provided during registration. The team leader will receive confirmation and further instructions via email or phone call.`
    }
];

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card" style={{  borderRadius: "5px", marginBottom: "10px", overflow: "hidden", borderColor: "white", backgroundColor: "#232D3F" }}>
            <div className="rc-accordion-header" style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "space-between", backgroundColor: active === id ? "black" : "black", transition: "0.3s" }}>
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <h5 className="rc-accordion-title" style={{ fontWeight: "500", fontSize: "18px", position: "relative", marginBottom: "0", color: active === id ? "#FFC100" : "white", transition: "0.3s", flex: "1" }}>{header}</h5>
                    <i className={`fa fa-chevron-down rc-accordion-icon`} style={{ color: "#FFC100", transition: "transform 0.3s", transform: active === id ? "rotate(180deg)" : "rotate(0deg)" }}></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={{ position: "relative", height: active === id ? "auto" : "0", overflow: "hidden", transition: "height 0.35s ease" }}>
                <div className="rc-accordion-body" style={{ flex: "1 1 auto", minHeight: "1px", padding: "15px" }}>
                    <p className='mb-0' style={{ marginBottom: "0", fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "white" }}>{text}</p>
                </div>
            </div>
        </div>
    )
}

const Accordion = () => {
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-8 mt-2">
                    {faqs.map((faq, index) => (
                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
